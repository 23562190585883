<template>
  <div>
    <b-modal
      id="claim-add-deduction"
      ref="modal"
      title="Add a deduction"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
    <div class="font-weight-bold red">
      <span v-for="(error, index) in errors" :key="index">{{ index }}:
        <span v-for="(msg, index_1) in error" :key="'msg_' + index_1">{{ msg }}</span>
      </span>
    </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Description"
          label-for="description-input"
          ref="add-deduction-description-input"
          invalid-feedback="Deduction is required"
          :state="descriptionState"
        >
        <b-form-input
            id="description-input"
            v-model="description"
            :state="descriptionState"
            :disabled="readonlyDescription"
            required
        ></b-form-input>
        </b-form-group>
        <b-form-group
          prepend="$" 
          label="Amount"
          label-for="amount-input"
          invalid-feedback="Amount is required"
          :state="amountState"
        >
        <b-form-input
            id="amount-input"
            type="number"
            v-model.number="amount"
            step="any"
            :state="amountState"
            required
        ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'

  export default {
    props: {
      preset_description: {
        type: String,
        required: true,
      },
      preset_amount: {
        type:  String,
        required: false,
      },
      update_csr: {
        type: Number,
        required: false,
      },
      update_blocker: {
        type: Number,
        required: false,
      }
    },
    watch: {
      preset_description: function(new_desc){
        this.description = new_desc;
      },
      preset_amount: function(new_amount){
        if(new_amount > 0){
          this.amount = new_amount;
        }
      }
    },
    data() {
      return {
        errors: {},
        description: '',
        amount: '',
        descriptionState: null,
        amountState: null,
      }
    },
    computed: {
      readonlyDescription() {
        return (this.preset_description.length > 0)
      },
      readonlyAmount() {
        return (this.preset_amount.length > 0)
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.descriptionState = valid
        this.amountState = valid
        return valid
      },
      resetModal() {
        this.description = ''
        this.amount = ''
        this.amountState = null
        this.descriptionState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        let data = {
            description: this.description,
            amount: this.amount
        }

        this.$store.state.loading = true;

        this.errors = {};

        axios.post('/claims/' + this.$route.params.id + '/deductions/', data).then(
          response => {
            response

            if(this.preset_description.startsWith("Inner Limits:")){
              for(var i = 0; i < this.$parent.quote.limits.length; i++){
                if(this.$parent.quote.limits[i].id == this.update_csr){
                  this.$parent.quote.limits[i].deduction_added = true;
                }
              }
              this.$parent.selected_limit_checks = this.$parent.selected_limit_checks.filter(
                id => (id !== this.update_csr)
              )
              
            } else if(this.update_csr > 0){
              this.$parent.approveCsr([this.update_csr], true);
              this.$parent.selected_exclusion_checks = this.$parent.selected_exclusion_checks.filter(
                id => (id !== this.update_csr)
              )
              this.$parent.selected_exclusion_checks_claim = this.$parent.selected_exclusion_checks_claim.filter(
                id => (id !== this.update_csr)
              )
              this.$parent.deduction_description = '';
              this.$parent.update_csr = 0;
            }
            if(this.update_blocker > 0){
              this.$parent.blockerUpdate(this.update_blocker, "approved");
              this.$parent.update_blocker = 0;
              //this.$parent.loadBlockers();
            }

            this.$store.state.loading = false;
            
            // Hide the modal manually
            //this.$parent.loadDeductions()
            this.$nextTick(() => {
              this.$bvModal.hide('claim-add-deduction')
            })
            this.resetModal()
            //this.$parent.loadBlockers()
            

          }
        ).catch(error => {

            this.amountState = false
            this.descriptionState = false

            this.$store.state.loading = false;

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            this.errors = error.response.data;

            console.log(error.response)
          })
        
      }
    }
  }
</script>