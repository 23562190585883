<template>
  <div>
    <b-modal
      id="change-claim-type"
      ref="change-claim-type"
      title="Change Claim Type"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
          label="Claim Type"
          label-for="claim-type"
          invalid-feedback="Claim Type is required"
          :state="validateState('claim_type')"
        >
        <b-form-select id="claim-type" v-model="$v.claim_type.$model" :options="claim_types"  :state="validateState('claim_type')"></b-form-select>
        </b-form-group>

        <b-form-group
          v-if="this.claim_type === 2"
          label="Associated Claim"
          label-for="parent-claim"
          invalid-feedback="Claim Type is required"
          :state="validateState('parent_claim')"
        >
          <b-form-select id="parent-claim" v-model="$v.parent_claim.$model" :options="other_claims" :state="validateState('parent_claim')"></b-form-select>
        </b-form-group>
        
      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'
  import { validationMixin } from 'vuelidate'
  import {required, requiredIf} from "vuelidate/lib/validators";

  export default {
    props: ['claim_type_in'],
    data() {
      return {
        error: false,
        claim_type: null,
        parent_claim: null,
        claimTypeState: null,
        parentClaimState: null
      }
    },
    mixin: [validationMixin],
    validations() {

      return {
          claim_type: {
            required
          },
          parent_claim: {
            required: requiredIf(function () {
                return this.claim_type === 2
            })
          },
      }

    },
    methods: {

      validateState(name) {

        if (this.$v[name]) {
          const {$dirty, $error} = this.$v[name];
          return $dirty ? !$error : null;
        }
      },
      
      resetModal() {
        this.claim_type = ''
        this.parent_claim = ''
        this.claimTypeState = null
        this.parentClaimState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        this.$v.$touch();

        if(this.$v.$anyError) {

          this.error = true;

          return

        } else {

          let data = {
              claim_type: this.claim_type
          }

          if (this.claim_type === 2) {
            data['previous_claim'] = this.parent_claim
          } else {
            data['previous_claim'] = null
          }

          axios.patch('/claims/' + this.$route.params.id + '/' , data).then(
            response => {
              response

              this.$v.$reset()
              this.$parent.getPolicy()
              // Hide the modal manually
              this.$nextTick(() => {
              this.$bvModal.hide('change-claim-type')
              })

            }
          ).catch(error => {

              this.claimTypeState = false

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }

              console.log(error.response)
            })

        }
        
      }
    },
    computed: {
      claim_types () {
        return this.$parent.options.claim_types
      },
      other_claims () {
        return [{value: null, text: 'Please select'},].concat(this.$parent.options.other_claims)
      }
    },
    mounted() {
      this.claim_type = this.claim_type_in
    }
  }
</script>