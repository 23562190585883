<template>

  <b-tab title="Policy" ref="policy-tab">

    <policy :id="id"></policy>

  </b-tab>

</template>

<script>
import Policy from '../../policy/Policy'

export default {
    components: {
      'policy': Policy
    },
    name: 'PolicyTab',
    props: {
      id: {
        type: Number,
        required: true,
      },
    }

}

</script>
