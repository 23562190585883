<template>
  <div>
    <b-modal
      id="claim-add-document"
      ref="modal"
      title="Add a document"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Description"
          label-for="description-input"
          invalid-feedback="Description is required"
          :state="descriptionState"
        >
        <b-form-input
            id="description-input"
            v-model="description"
            :state="descriptionState"
            required
            placeholder="Enter something..."
        ></b-form-input>

        </b-form-group>

        <b-form-group
          label="File"
          label-for="file-input"
          invalid-feedback="File is required"
          :state="fileState"
        >
        <b-form-file
            id="file-input"
            v-model="file"
            :state="fileState"
            required
        ></b-form-file>

        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons'
  
  import axios from '../../../../shared/axios/auth-no-loader'

  export default {
    data() {
      return {
        description: '',
        descriptionState: null,
        file: '',
        fileState: null,
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.descriptionState = valid
        this.fileState = valid
        return valid
      },
      resetModal() {
        this.description = ''
        this.descriptionState = null
        this.file = ''
        this.fileState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        console.log(this.file)
        // Push the name to submitted names
        let data = {
            description: this.description,
            filedata: this.file,
        }
        let formData = new FormData();
        formData.append("description", this.description);
        formData.append("filedata", this.file);
        axios.post('/claims/' + this.$route.params.id + '/extra-docs/', formData, {
          "headers": {
            "Content-Type": "multipart/form-data"
          }
        }).then(
          response => {
            response
            this.description = ''
            this.file = ''

            // Hide the modal manually
            this.$parent.getPolicy()
            this.$nextTick(() => {
            this.$bvModal.hide('claim-add-document')
            })

          }
        ).catch(error => {

            this.documentState = null
            this.fileState = null

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        
      }
    }
  }
</script>