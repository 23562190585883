<template>

  <b-tab title="Tasks" ref="tasks-tab">

    <b-button @click="loadTasks(null)">Refresh</b-button>

    <div v-if="tasks && tasks.data.length > 0">

      <table>
      <tr>
          <td class="p-2">
          <b-button v-if="tasks.pagination.first_page_url === null">&#60;&#60; First</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.first_page_url);">&#60;&#60; First</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.previous_page_url === null">&#60; Prev</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.previous_page_url);">&#60; Prev</b-button>
          </td>
          <td class="p-2">
          <b>Page
          {{ tasks.pagination.current_page === null ? 1 : tasks.pagination.current_page }}
          of 
          {{ tasks.pagination.last_page }}
          </b>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.next_page_url === null">Next &#62;</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.next_page_url);">Next &#62;</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.last_page_url === null">Last &#62;&#62;</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.last_page_url);">Last &#62;&#62;</b-button>
          </td>
      </tr>
      </table>

      <b-card  v-for="(task, indexI) in tasks.data" :key="'key5_'+indexI">
        <b-card-text>
          <table>
            <tr>
              <td class="p-2"><b>Task Type:</b></td>
              <td class="p-2">{{task.type}}</td>
            </tr>
            <tr>
              <td class="p-2"><b>Due Date:</b></td>
              <td class="p-2">{{task.due_date}}</td>
            </tr>
            <tr>
              <td class="p-2"><b>Description:</b></td>
              <td class="p-2">{{task.description}}</td>
            </tr>
            <tr>
              <td class="p-2"><b>Status:</b></td>
              <td class="p-2">{{task.status_pretty}}</td>
            </tr>
            <tr>
              <td class="p-2"><b>Assigned To:</b></td>
              <td class="p-2">{{task.assigned_to_pretty}}</td>
            </tr>
          </table>
        </b-card-text>
        <template #footer>
          <span class="float-right"><b>Created:</b>&nbsp;<em>{{task.created_at}}</em></span>
        </template>
      </b-card>

            <table>
      <tr>
          <td class="p-2">
          <b-button v-if="tasks.pagination.first_page_url === null">&#60;&#60; First</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.first_page_url);">&#60;&#60; First</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.previous_page_url === null">&#60; Prev</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.previous_page_url);">&#60; Prev</b-button>
          </td>
          <td class="p-2">
          <b>Page
          {{ tasks.pagination.current_page === null ? 1 : tasks.pagination.current_page }}
          of 
          {{ tasks.pagination.last_page }}
          </b>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.next_page_url === null">Next &#62;</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.next_page_url);">Next &#62;</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="tasks.pagination.last_page_url === null">Last &#62;&#62;</b-button>
          <b-button v-else @click="loadTasks(tasks.pagination.last_page_url);">Last &#62;&#62;</b-button>
          </td>
      </tr>
      </table>

    </div>

    <div v-else>
      <p class="text-center">No tasks added yet.</p>
    </div>

    <!--<add-task v-bind:type="'claim'"></add-task>-->
    <b-button v-b-modal="'task-add-task'" :task_type="'claim'">Add {{ type.ucwords() }} Task</b-button>

  </b-tab>

</template>

<script>
import axios from '../../../../shared/axios/auth-no-loader'
import addTask from '../../task/add-task-modal'

export default {
    components: {
      addTask
    },
    name: 'TasksTab',
    props: {
      type: {
        required: true,
      },
      object: {
        required: true,
      },
    },
    data () {
        return {
            loaded: false,
            response: {},
            tasks: null,
        }
    },
    methods: {
      loadTasks (url=null) {

        if(url == null){
          url = '/tasks/?'+this.type+'=' + this.object
        }

        axios.get(url).then(
          response => {
            this.tasks = response.data
            this.scrollToTop()
            //this.loadTasks()
          }
        )

      },
      scrollToTop(){
        window.scrollTo(0,0);
      }
    },
    mounted() {
      this.loadTasks()
    }

}

</script>
