<template>
  <div>
    <b-modal
      ref="status-modal-decline" 
      id="status-modal-decline"
      title="Decline Claim"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
 
        <b-form-group
          label="Reason"
          label-for="correspondence-input2"
          invalid-feedback="Reason is required"
          :state="correspondenceState"
        >
        <b-form-select v-model="reason" id="correspondence-input2" :state="correspondenceState" :options="options" required></b-form-select>
        </b-form-group>

        <b-form-group
          label="Internal reason for decline"
          label-for="correspondence-input"
          invalid-feedback="Reason explanation is required"
          :state="correspondenceState"
        >
        <b-form-textarea
            id="correspondence-input"
            v-model="description"
            :state="correspondenceState"
            required
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>

        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}
  export default {
    props: {
      reference: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        reason: '',
        description: '',
        correspondenceState: null,
        options: [{'value': null, 'text': 'Please select'}, {'value': 1, 'text': 'Benefit Limit Reached'}, {'value': 2, 'text': 'No Policy In Force'}, {'value': 3, 'text': 'Policy Terms'}, {'value': 4, 'text': 'Under Excess'}, {'value': 4, 'text': 'Other'}]
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.correspondenceState = valid
        return valid
      },
      resetModal() {
        this.description = ''
        this.reason = ''
        this.correspondenceState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names

        let url
        let data

          url = '/claims/' + this.$route.params.id + '/decline/'
          data = {
              reason: this.reason,
              reason_text: this.description
          }

        axios.post(url, data).then(
          response => {
            response
            this.description = ''
            this.reason = null

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('status-modal-decline')
            })

            this.$parent.getPolicy();
            this.$parent.$refs['general-tab'].active = true

          }
        ).catch(error => {

            this.correspondenceState = false

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            

            console.log(error.response)
        })
        
      }
    }
  }
</script>