<template :key="refresh_key">
  <div class="animated fadeIn">
  <div>
    <b-alert variant="success" :show="isSplitSuccessVisible">
      The claim has been split, and you are now viewing the copy of the claim.<br/>
      <b-button @click="hideSplitSuccess">OK</b-button>
    </b-alert>
    <b-alert variant="success" :show="isReopenSuccessVisible">
      The claim has been reopened, and you are now viewing the copy of the claim.<br/>
      <b-button @click="hideReopenSuccess">OK</b-button>
    </b-alert>
    <b-alert variant="success" :show="isClaimRouteSuccessVisible">
      The claim has been passed to the claim route.<br/>
      <b-button @click="hideClaimRouteSuccess">OK</b-button>
    </b-alert>
  </div>
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span v-if="loaded" class="mt-2">Claim Reference:  {{ quote.policy_number }} </span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card v-if="loaded" no-body id="data">

          <b-tabs card ref="general-tab" v-model="activeClaimTab">

            <b-tab v-for="(tab, indexA) in quote.data" :key="'tab_' + indexA" :ref="'tab_'+tab.title.toLowerCase().replace(/\s/g, '_')" :title="tab.title" :class="{ 'active': indexA === 0 }" class="mt-2">

              <div>

                <div v-if="tab.type === 'html'" v-html="tab.data">

                  {{ tab.data }}

                </div>

                <div v-else-if="tab.type === 'dynamic_claim_data'">
                  <template v-if="tab.data_type === 'policy_holder'">
                    <template v-for="(section, section_key) in claimDataOrdered">
                      <spark-claim-data
                        :key="section_key + '_' + tab.data_type"
                        :section="quote.section"
                        :type="tab.data_type"
                        :item="section"
                        :root="true"
                      ></spark-claim-data>
                    </template>
                  </template>
                  <template v-if="tab.data_type === 'vet'">
                    <template v-for="(section, section_key) in vetClaimDataOrdered">
                      <spark-claim-data
                        :key="section_key + '_' + tab.data_type"
                        :section="quote.section"
                        :type="tab.data_type"
                        :item="section"
                        :root="true"
                      ></spark-claim-data>
                    </template>
                  </template>
                </div>

                <div v-else>

                  <table class="table">
                    <tr v-for="(item, key, indexB) in tab.data" :key="'display_' + indexB">
                      <th v-if="key !== 'customer_id' && key !== 'vet_id' && key !== 'policy_id'">
                        {{ key | capitalize }}
                      </th>
                      <td v-if="key === 'claim_sub_type'">
                        <b-form-select v-if="options.claim_sub_types" v-model="claim_sub_type" :options="options.claim_sub_types" v-on:change="updateClaimSubType($event)"></b-form-select>
                      </td>
                      <td v-else-if="key === 'customer'">
                        <router-link :to="{name: 'Customer', params: {id: quote.data[0].data.customer_id}}">{{ item }}</router-link>
                        
                      </td>
                      <td v-else-if="key === 'vet'">
                        <router-link :to="{name: 'Vet', params: {id: quote.data[0].data.vet_id}}">{{ item }}</router-link>
                      </td>
                      <td v-else-if="key === 'policy_number'">
                        <router-link :to="{name: 'Policy', params: {id: quote.data[0].data.policy_id}}">{{ item }}</router-link>
                      </td>
                      <td v-else-if="key === 'previous_claim'">
                        <router-link :to="{name: 'Claim', params: {id: item.id}}">{{ item.reference }}</router-link>
                      </td>
                      <td v-else-if="key === 'child_claim'">
                        <router-link :to="{name: 'Claim', params: {id: item.id}}">{{ item.reference }}</router-link>
                      </td>
                      <td v-else-if="key === 'decline_reason'">
                        <table>
                          <tr><td>Official Reason:</td><td>{{ item[0] }}</td></tr>
                          <tr><td>Staff Decision Reason:</td><td>{{ item[1] }}</td></tr>
                        </table>
                      </td>
                      <td v-else-if="key !== 'customer_id' && key !== 'vet_id' && key !== 'policy_id'" v-html="item">
                        {{ item }}
                      </td>
                    </tr>
                  </table>

                </div>

              </div>

            </b-tab>

            <policy-tab v-if="quote" v-bind:id="quote.policy_id"></policy-tab>

            <b-tab v-show="quote && quote.documents.length > 0" title="Documents">

                <div class="row border-bottom py-2"> 
                  <div class="col">
                    <b>Name</b>
                  </div>
                  <div class="col-2">
                    <b>Uploaded</b>
                  </div>
                  <div class="col-3">
                    <b>Uploaded By</b>
                  </div>
                  <div class="col-2">
                    <b>Action</b>
                  </div>
                </div>

                <div v-for="(document, indexC) in quote.documents" :key="'docs_' + indexC">

                  <div class="row py-2 border-bottom">

                    <div class="col" v-html="document.name">
                      {{document.name}}
                    </div>
                    <div class="col-2">
                        <b-icon v-if="document.uploaded"  icon="check" variant="success" font-scale="2"></b-icon>
                        <b-icon v-else icon="x" variant="danger" font-scale="2"></b-icon>
                    </div>
                    <div class="col-3">
                      {{document.uploaded_by}}
                    </div>
                    <div class="col-2">
                      <!--<b-button size="sm" v-if="document.uploaded && document.extra && document.extra.length > 0" variant="outline-dark" v-b-toggle="'collapse-doc-' + retrieve class="ml-2">View data</b-button>-->
                      <!--<b-button  v-if="document.uploaded" variant="light" v-b-modal="index + '-attachment'" class="btn btn-outline-dark btn-sm">View</b-button>-->
                      <!--<b-button size="sm" v-if="document.uploaded && document.extra && document.extra.length > 0" variant="outline-dark" v-b-toggle="'collapse-doc-' + retrieve class="ml-2">View data</b-button>-->
                      <!--<filemodal v-bind:id="'content-modal-' + index + '-document'" v-bind:content="document.data"></filemodal>-->
                      <b-button class="btn btn-outline-dark btn-sm" size="sm" variant="light" v-if="document.uploaded" @click="openInNewTab(document.data);">View File</b-button>
                      <!--<b-button class="btn btn-outline-dark btn-sm mt-2" size="sm" variant="light" v-if="document.uploaded && document.extra && document.extra.length > 0" v-b-toggle="'collapse-doc-' + indexC">View Data</b-button>-->
                    </div>

                  </div>

                  <b-collapse v-if="document.extra && document.extra.length > 0" :id="'collapse-doc-' + indexC">
                    <div class="row bg-light">
                      <div class="col">
                        
                        <table class="table" >
                            <tr v-for="(value, indexC_) in document.extra" :key="'docs_extra_' + indexC_">
                                <th>{{value.label|capitalize}}</th>
                                <td v-if="document.meta">{{ document.meta[value.name]}}</td>
                                <td v-else></td>
                            </tr>
                        </table>

                      </div>
                    </div>
                  </b-collapse>

                </div>

                <div v-for="(document, indexD) in quote.extra_documents" :key="'docs_extra_show_' + indexD">

                  <div class="row py-2 border-bottom">

                    <div class="col">
                      Extra Document: {{document.name}}
                    </div>
                    <div class="col">
                        {{document.user_name}} [{{document.user_type}}]
                    </div>
                    <div class="col-2">
                      <b-button class="btn btn-outline-dark btn-sm" size="sm" variant="light" @click="openInNewTab(document.link);">View File</b-button>
                    </div>

                  </div>

                </div>

            </b-tab>

            <diary-tab v-if="quote" v-bind:type="'claim'"></diary-tab>

            <b-tab v-if="quote.show_data && deductions" @click="loadDeductions()" title="Claim Breakdown" ref="tab_claim_breakdown">


                <div class="row">
                  <div class="col">

                    <table class="table">
                      <tr>
                        <th colspan="3" class="bg-light">Breakdown</th>
                        <th class="bg-light"><b-button size="sm" v-b-modal="'claim-add-deduction'" variant="outline-dark"  class="float-right mr-3">Add deduction</b-button></th>
                      </tr>

                      <tr>
                          <th>Type</th>
                          <th>Description</th>
                          <th>Amount</th>
                          <th>Added By</th>
                      </tr>

                      <tr v-for="(deduction, indexF) in deductions.data" :key="'deduction_' + indexF">
                        <td>{{deduction.type}}</td>
                        <td>{{deduction.description}}</td>
                        <td>&pound;{{deduction.amount}}</td>
                        <td>{{deduction.created_by}}</td>
                      </tr>

                      <tr>
                        <td class="bg-light"></td>
                        <th class="bg-light text-right" colspan="2">Total Claim Amount</th>
                        <th class="bg-light">&pound;{{deductions.total}}</th>
                      </tr>
                      
                    </table>

                  </div>

                </div>

            </b-tab>

            <b-tab v-if="quote.show_data" title="Claim Assesment" @click="loadBlockers">

              <b-tabs card>

                <b-tab v-if="quote.show_data" title="Claim Audit" key="1">

                    <div class="row border-bottom py-2"> 
                      <div class="col-2">
                        <b>Step No.</b>
                      </div>
                      <div class="col-7">
                        <b>Name</b>
                      </div>
                      <div class="col-1">
                        <b>Result</b>
                      </div>
                      <div class="col">
                        <b>Action</b>
                      </div>
                    </div>

                    <div v-for="(step, indexG) in quote.audit" :key="'claim_route_audit_' + indexG">

                      <div class="row py-2 border-bottom">

                        <div class="col-2">
                          {{step.step_no}}
                        </div>
                        <div class="col-7">
                          {{step.name}}
                        </div>
                        <div class="col-1">
                            <b-icon v-if="step.result"  icon="check" variant="success" font-scale="2"></b-icon>
                            <b-icon v-else icon="x" variant="danger" font-scale="2"></b-icon>
                        </div>
                        <div class="col">
                          <b-button size="sm" v-if="!isEmpty(step.data)" variant="outline-dark" v-b-toggle="'collapse-' + indexG">View data</b-button>
                        </div>

                      </div>

                      <b-collapse :id="'collapse-' + indexG">
                        <div class="row bg-light">
                          <div class="col">
                            <pre v-if="step.data.initial_fraud_checks">{{step.data.initial_fraud_checks}}</pre>

                            <table class="table" >
                                <tr v-for="(value, key, indexG_) in step.data" :key="'fraud_checks_' + indexG_">
                                    <th>{{key|capitalize}}</th>
                                    <td>{{value}}</td>
                                </tr>
                            </table>

                            <table v-if="step.data.initial_fraud_checks" class="table">
                              <tr>
                                <th>Safe</th>
                                <th>Risk Score</th>
                                <th></th>
                              </tr>
                              <tr>
                                <td>
                                    <b-icon v-if="step.data.initial_fraud_checks.safe"  icon="check" variant="success" font-scale="2"></b-icon>
                                    <b-icon v-else icon="x" variant="danger" font-scale="2"></b-icon>
                                </td>
                                <td>{{step.data.initial_fraud_checks.risk_score}}</td>
                                <td>{{step.data.initial_fraud_checks.risk_factors}}</td>
                              </tr>
                            </table>

                            <table v-if="step.data.initial_fraud_checks" class="table" >
                                <tr>
                                  <th>Step No.</th>
                                  <th>Name</th>
                                  <th>Result</th>
                                </tr>
                                <tr v-for="(data, fraud_index) in step.data.initial_fraud_checks.data" :key="'fraud_checks_2_' + fraud_index">
                                  <td>{{data.step_no}}</td>
                                  <td>{{data.name}}</td>
                                  <td>
                                    <b-icon v-if="data.result"  icon="check" variant="success" font-scale="2"></b-icon>
                                    <b-icon v-else icon="x" variant="danger" font-scale="2"></b-icon>
                                  </td>
                                </tr>
                            </table>
                          </div>
                        </div>
                      </b-collapse>

                    </div>

                </b-tab>

                <b-tab title="Assessment Helper" v-if="canShowAssessmentHelper">
                  <template v-if="assessment_step == 'extra_blockers'">
                    <h4>You have a Claim Blocker</h4>
                    <p>{{ blockersNotAssessment[0].reason }}</p>
                    <template v-if="blockersNotAssessment[0].reason === 'Has the claim been flagged as a possible continuation claim?'">
                      <b-button variant="warning" class="mt-2 mb-4" @click="loadContinuationClaimData">Load Claims</b-button>
                      <template v-if="'linked' in continuation_claim_linked">
                        <h5>Linked claims via automation / claim form</h5>
                        <b-select class="mt-2 mb-4 col-10" v-model="continuation_claim_selected_linked">
                          <b-select-option :value="null">-- Please select --</b-select-option>
                          <b-select-option
                            v-for="(linked, link_key) in continuation_claim_linked.linked"
                            :key="link_key"
                            :value="linked.id"
                            v-html="linked.claim_number + ' - ' + linked.claim_description"
                          ></b-select-option>
                        </b-select>
                        <b-button 
                          v-if="continuation_claim_selected_linked !== null"
                          variant="success" 
                          class="ml-2 mt-2 mb-4" 
                          @click="previewContinuationClaimData('linked')"
                        >Preview Claim</b-button>
                      </template>
                      <template v-if="'all' in continuation_claim_linked">
                        <h5>All claims linked to this pet [MANUAL OVERRIDE]</h5>
                        <b-select class="mt-2 mb-4 col-10" v-model="continuation_claim_selected_all">
                          <b-select-option :value="null">-- Please select --</b-select-option>
                          <b-select-option
                            v-for="(linked, link_key) in continuation_claim_linked.all"
                            :key="link_key"
                            :value="linked.id"
                            v-html="linked.claim_number + ' - ' + linked.claim_description"
                          ></b-select-option>
                        </b-select>
                        <b-button 
                          v-if="continuation_claim_selected_all !== null"
                          variant="success" 
                          class="ml-2 mt-2 mb-4" 
                          @click="previewContinuationClaimData('all')"
                        >Preview Claim</b-button>
                      </template>
                      <template v-if="continuation_claim_preview">
                        <table>
                          <tr v-for="(v, k, index) in continuation_claim_preview" :key="index">
                            <th class="p-2">{{ String(k).replace(/_/g, " ").ucwords() }}</th>
                            <td v-if="k === 'claim_number'" class="p-2">
                              <a :href="$router.resolve({name: 'Claim', params: {id: continuation_claim_preview.id}}).href" target="_blank">{{ continuation_claim_preview.claim_number }}</a>
                            </td>
                            <td v-else class="p-2">{{ v }}</td>
                          </tr>
                        </table>
                        <b-button variant="success" class="mt-2 mb-4" @click="setContinuationLinkedClaim">Assign As Most Recent Linked Claim</b-button>
                        <div class="col-12"></div>
                        <b-button variant="danger" class="mt-4" @click="blockerUpdate(blockersNotAssessment[0].id, 'approved')">Approve Blocker Then Continue</b-button>
                      </template>
                    </template>
                    <template v-else>
                      <b-button variant="danger" v-b-modal="'status-modal-decline'">Decline Claim</b-button><br/>
                      <b-button variant="danger" class="mt-2" v-b-modal="'status-modal-decline-review'">Decline Claim w/ Policy Review</b-button><br/>
                      <b-button variant="success" class="mt-2" @click="addDeductionWithPrefillData(0, blockersNotAssessment[0].id, blockersNotAssessment[0].reason)">Add Deduction Then Continue</b-button><br/>
                      <b-button variant="warning" class="mt-2" @click="blockerUpdate(blockersNotAssessment[0].id, 'approved')">Approve Blocker Then Continue</b-button>
                    </template>
                  </template>
                  <template v-if="assessment_step == 'exclusion_checks_all'">
                    <h4>Please tick the box if any of these exclusions apply:</h4>
                    <p><b>[NOTE: will decline claim if item is ticked and deduction not added]</b></p>
                    <table>
                      <tr v-for="(check) in exclusionChecksAll" :key="'exclusion_checks_' + check.id">
                        <td class="py-1 px-2 align-top">
                          <b-checkbox :ref="'exclusion_checkbox_'+check.id" class="mt-1" v-model="selected_exclusion_checks" :value="check.id"></b-checkbox>
                        </td>
                        <td class="py-2 px-2">
                          <span>{{ check.description }}</span>
                          <div v-if="$refs['exclusion_checkbox_'+check.id] && $refs['exclusion_checkbox_'+check.id][0]">
                            <span v-if="check.exclusion.deduction && $refs['exclusion_checkbox_'+check.id][0].isChecked">
                              <b-button variant="warning" @click="addDeductionWithPrefillData(check.id, 0, 'Excluded Item: '+check.description);">Add Deduction (Optional)</b-button>
                              Will decline claim if no deduction added.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <b-button class="align-right mt-3" variant="success" @click="processExclusionChecksAll">Next</b-button>
                  </template>
                  <template v-if="assessment_step == 'exclusion_checks_claim'">
                    <h4>Please tick the box if any of these exclusions apply:</h4>
                    <p><b>[NOTE: will decline claim if item is ticked and deduction not added]</b></p>
                    <table>
                      <tr v-for="(check) in exclusionChecksClaim" :key="'exclusion_checks_claim_' + check.id">
                        <td class="py-1 px-2 align-top">
                          <b-checkbox :ref="'exclusion_checkbox_'+check.id" class="mt-1" v-model="selected_exclusion_checks_claim" :value="check.id"></b-checkbox>
                        </td>
                        <td class="py-2 px-2">
                          <span>{{ check.description }}</span>
                          <div v-if="$refs['exclusion_checkbox_'+check.id] && $refs['exclusion_checkbox_'+check.id][0]">
                            <span v-if="check.exclusion.deduction && $refs['exclusion_checkbox_'+check.id][0].isChecked">
                              <b-button variant="warning" @click="addDeductionWithPrefillData(check.id, 0, 'Excluded Item: '+check.description);">Add Deduction (Optional)</b-button>
                              Will decline claim if no deduction added.
                            </span>
                          </div>
                         </td>
                      </tr>
                    </table>
                    <b-button class="align-right mt-3" variant="success" @click="processExclusionChecksClaim">Next</b-button>
                  </template>
                  <template v-if="assessment_step == 'fraud_checks'">
                    <h4>Please tick the box if any of these fraud conditions apply, under suspicious circumstances:</h4>
                    <p><b>[NOTE: will decline claim if any are ticked]</b></p>
                    <table>
                      <tr v-for="(check) in fraudChecks" :key="'fraud_checks_3_' + check.id">
                        <td class="py-1 px-2 align-top">
                          <b-checkbox class="mt-1" v-model="selected_fraud_checks" :value="check.id"></b-checkbox>
                        </td>
                        <td class="py-2 px-2">
                          <span>{{ check.description }}</span>
                         </td>
                      </tr>
                    </table>
                    <b-button class="align-right mt-3" variant="success" @click="processFraudChecks">Next</b-button>
                  </template>
                  <template v-if="assessment_step == 'manual_checks'">
                    <template v-if="this.manual_checks_step == 'Cost containment checks'">
                      <h4>{{ this.manual_checks_step }}</h4>
                      <h5>Add Medication Item</h5>
                      <table cellpadding="5">
                        <tr>
                          <td align="right">Type medication name</td>
                          <td>
                            <b-input v-model="cost_containment_medication_search"/>
                          </td>
                        </tr>
                        <tr v-if="cost_containment_medication_search.length > 0">
                          <td align="right">Select medication</td>
                          <td>
                            <b-select v-model="cost_containment_medication_selected" style="min-width: 300px;">
                              <b-select-option
                                v-for="(item, index) in cost_containment_medication_search_results"
                                :key="'cost_cont_' + index"
                                :value="item.id"
                                v-html="item.name"
                              />
                            </b-select>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem">
                          <td align="right">Quantity of items on invoice</td>
                          <td>
                            <b-input v-model="cost_containment_medication_quantity"/>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem">
                          <td align="right">Gross amount per 1x item</td>
                          <td>
                            <b-input v-model="cost_containment_medication_gross"/>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem">
                          <td align="right">Vat amount for this item (auto calculated @ 20%)</td>
                          <td>
                            <b-input :disabled="true" v-model="cost_containment_medication_vat"/>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem">
                          <td align="right">Net amount for this item (auto calculated)</td>
                          <td>
                            <b-input :disabled="true" v-model="cost_containment_medication_net"/>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem">
                          <td align="right">Total amount for this item (auto calculated)</td>
                          <td>
                            <b-input :disabled="true" v-model="cost_containment_medication_total"/>
                          </td>
                        </tr>
                        <tr v-if="canAddCostContainmentItem && cost_containment_medication_total.length > 0">
                          <td></td>
                          <td>
                            <b-button @click="addCostContainmentItemToClaim">Add Item To Claim</b-button>
                          </td>
                        </tr>
                      </table>
                      <h5 class="mt-10" v-if="cost_containment_medication_in_claim.length > 0">Medication Items</h5>
                      <table v-if="cost_containment_medication_in_claim.length > 0">
                        <tr>
                          <td class="p-2 font-weight-bold">Medication Name</td>
                          <td class="p-2 font-weight-bold">Quantity</td>
                          <td class="p-2 font-weight-bold">Vet Price Each (Gross)</td>
                          <td class="p-2 font-weight-bold">Calc Price (Net)</td>
                          <td class="p-2 font-weight-bold">Calc Price (Vat)</td>
                          <td class="p-2 font-weight-bold">Calc Price (Total)</td>
                          <td class="p-2 font-weight-bold">Deduction Applied</td>
                        </tr>
                        <tr v-for="(item, index) in cost_containment_medication_in_claim" :key="'manual_checks_' + index">
                          <td class="p-2">{{ item.name }}</td>
                          <td class="p-2 text-right">{{ item.quantity }}</td>
                          <td class="p-2 text-right">&pound;{{ item.gross.toFixed(2) }}</td>
                          <td class="p-2 text-right">&pound;{{ item.net.toFixed(2) }}</td>
                          <td class="p-2 text-right">&pound;{{ item.vat.toFixed(2) }}</td>
                          <td class="p-2 text-right">&pound;{{ item.total.toFixed(2) }}</td>
                          <td class="p-2 text-right" v-if="item.deduction_required === true">
                            <i class="fas fa-check-circle green icon-large"></i>
                            &nbsp;&nbsp;&pound;{{ item.deduction_amount.toFixed(2) }}
                          </td>
                          <td class="p-2" v-else>
                            <i class="fas fa-times-circle red icon-large"></i>
                            &nbsp;&nbsp;NO
                          </td>
                        </tr>
                      </table>
                      <b-button class="align-right mt-3" variant="success" @click="approveCsr([manualChecks[0].id], true);">Next</b-button>
                    </template>
                    <template v-if="this.manual_checks_step == 'Inner Limit checks'">
                      <b-row>
                        <b-col class="pb-4">
                          <h4>{{ this.manual_checks_step }}</h4>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="pb-4">
                          <h4>Limits per appointment</h4>
                        </b-col>
                      </b-row>
                      <b-row v-for="(check) in innerLimitAppointmentChecks" :key="'inner_limits_appt_' + check.id">
                        <b-col class="py-2 px-2">
                          <b-row>
                            <b-col class="font-weight-bold pb-3">{{ check.type }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col class="pb-3">
                              <i
                                v-if="limits.inner_limits_vet_data[check.type_code].claiming === true"
                                class="fas fa-check-circle green icon-large"
                              ></i>
                              <i v-else class="fas fa-times-circle red icon-large"></i>
                              <span>Involved in claim?</span>
                            </b-col>
                          </b-row>
                          <template v-if="limits.inner_limits_vet_data[check.type_code].claiming === true">
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2" class="font-weight-bold">Number of appointments</b-col>
                              <b-col cols="2" class="font-weight-bold">Cost per appointment</b-col>
                              <b-col cols="2" class="font-weight-bold">Limit per appointment</b-col>
                              <b-col cols="2" class="font-weight-bold">Deduction per appointment</b-col>
                              <b-col cols="2" class="font-weight-bold">Total deduction amount</b-col>
                              <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                            </b-row>
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2" v-html="limits.inner_limits_vet_data[check.type_code].total_visits"></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(check.limit)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit) * limits.inner_limits_vet_data[check.type_code].total_visits)"></span></b-col>
                              <b-col cols="2">
                                <p v-if="check.deduction_added">
                                  <i class="fas fa-check-circle green icon-large"></i>
                                  Already added
                                </p>
                                <b-button 
                                  v-else-if="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit) * limits.inner_limits_vet_data[check.type_code].total_visits > 0"
                                  variant="warning" 
                                  @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, formatToDecimal(innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit) * limits.inner_limits_vet_data[check.type_code].total_visits));"
                                  >Add Deduction</b-button>
                                <p v-else>
                                  <i class="fas fa-times-circle red icon-large"></i>
                                  Not needed
                                </p>
                              </b-col>
                            </b-row>
                          </template>
                         </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="pb-4">
                          <h4>Limits per condition</h4>
                        </b-col>
                      </b-row>
                      <b-row v-for="(check) in innerLimitConditionChecks" :key="'inner_limits_conds_' + check.id">
                        <b-col class="py-2 px-2">
                          <b-row>
                            <b-col class="font-weight-bold pb-3">{{ check.type }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col class="pb-3">
                              <i
                                v-if="limits.inner_limits_vet_data[check.type_code].claiming === true"
                                class="fas fa-check-circle green icon-large"
                              ></i>
                              <i v-else class="fas fa-times-circle red icon-large"></i>
                              <span>Involved in claim?</span>
                            </b-col>
                          </b-row>
                          <template v-if="limits.inner_limits_vet_data[check.type_code].claiming === true && limits.inner_limits_vet_data[check.type_code].total_visits">
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2" class="font-weight-bold">Number of appointments</b-col>
                              <b-col cols="2" class="font-weight-bold">Cost per appointment</b-col>
                              <b-col cols="2" class="font-weight-bold">Total cost</b-col>
                              <b-col cols="2" class="font-weight-bold">Limit</b-col>
                              <b-col cols="2" class="font-weight-bold">Total deduction amount</b-col>
                              <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                            </b-row>
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2" v-html="limits.inner_limits_vet_data[check.type_code].total_visits"></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(check.limit)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit)"></span></b-col>
                              <b-col cols="2">
                                <p v-if="check.deduction_added">
                                  <i class="fas fa-check-circle green icon-large"></i>
                                  Already added
                                </p>
                                <b-button 
                                  v-else-if="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit) > 0"
                                  variant="warning" 
                                  @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit));"
                                  >Add Deduction</b-button>
                                <p v-else>
                                  <i class="fas fa-times-circle red icon-large"></i>
                                  Not needed
                                </p>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-else-if="limits.inner_limits_vet_data[check.type_code].claiming === true">
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2" class="font-weight-bold">Cost entered by vet</b-col>
                              <b-col cols="2" class="font-weight-bold">Limit</b-col>
                              <b-col cols="2" class="font-weight-bold">Deduction amount</b-col>
                              <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                            </b-row>
                            <b-row class="pb-2 justify-content-left">
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="formatToDecimal(check.limit)"></span></b-col>
                              <b-col cols="2">&pound;<span v-html="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit)"></span></b-col>
                              <b-col cols="2">
                                <p v-if="check.deduction_added">
                                  <i class="fas fa-check-circle green icon-large"></i>
                                  Already added
                                </p>
                                <b-button 
                                  v-else-if="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit) > 0"
                                  variant="warning" 
                                  @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit));"
                                  >Add Deduction</b-button>
                                <p v-else>
                                  <i class="fas fa-times-circle red icon-large"></i>
                                  Not needed
                                </p>
                              </b-col>
                            </b-row>
                          </template>
                         </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="pb-4">
                          <h4>Limits per policy</h4>
                        </b-col>
                      </b-row>
                      <b-row v-for="(check) in innerLimitPolicyChecks" :key="'inner_limit_policy_' + check.id">
                        <template v-if="check.type_code === 'dp'">
                          <b-col class="py-2 px-2">
                            <b-row>
                              <b-col class="font-weight-bold pb-3">{{ check.type }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="pb-3">
                                <i
                                  v-if="limits.inner_limits_vet_data[check.type_code].death_percentage_payout === 100"
                                  class="fas fa-times-circle green icon-large"
                                ></i>
                                <i v-else class="fas fa-check-circle red icon-large"></i>
                                <span>Deduction Required?</span>
                              </b-col>
                            </b-row>
                            <b-row v-if="limits.inner_limits_vet_data[check.type_code].death_percentage_payout < 100">
                              <b-col>
                                <b-row class="pb-2 justify-content-left">
                                  <b-col cols="2" class="font-weight-bold">Maximum Percentage</b-col>
                                  <b-col cols="2" class="font-weight-bold">Pet Purchase Price</b-col>
                                  <b-col cols="2" class="font-weight-bold">Maximum Payout</b-col>
                                  <b-col cols="2" class="font-weight-bold">Deduction Amount</b-col>
                                  <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                                </b-row>
                                <b-row class="pb-2 justify-content-left">
                                  <b-col cols="2">{{ limits.inner_limits_vet_data[check.type_code].death_percentage_payout }} %</b-col>
                                  <b-col cols="2">&pound; {{ formatToDecimal(limits.inner_limits_vet_data[check.type_code].purchase_price) }}</b-col>
                                  <b-col cols="2">&pound; {{ calculatePercentageDeduction(limits.inner_limits_vet_data[check.type_code].death_percentage_payout, limits.inner_limits_vet_data[check.type_code].purchase_price) }}</b-col>
                                  <b-col cols="2">&pound; {{ formatToDecimal(formatToDecimal(limits.inner_limits_vet_data[check.type_code].purchase_price) - calculatePercentageDeduction(limits.inner_limits_vet_data[check.type_code].death_percentage_payout, limits.inner_limits_vet_data[check.type_code].purchase_price)) }}</b-col>
                                  <b-col cols="2">
                                    <p v-if="check.deduction_added">
                                      <i class="fas fa-check-circle green icon-large"></i>
                                      Already added
                                    </p>
                                    <b-button 
                                      v-else
                                      variant="warning" 
                                      @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, formatToDecimal(formatToDecimal(limits.inner_limits_vet_data[check.type_code].purchase_price) - calculatePercentageDeduction(limits.inner_limits_vet_data[check.type_code].death_percentage_payout, limits.inner_limits_vet_data[check.type_code].purchase_price))); this.getPolicy();"
                                      >Add Deduction</b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col class="py-2 px-2">
                            <b-row>
                              <b-col class="font-weight-bold pb-3">{{ check.type }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="pb-3">
                                <i
                                  v-if="limits.inner_limits_vet_data[check.type_code].claiming === true"
                                  class="fas fa-check-circle green icon-large"
                                ></i>
                                <i v-else class="fas fa-times-circle red icon-large"></i>
                                <span>Involved in claim?</span>
                              </b-col>
                            </b-row>
                            <template v-if="limits.inner_limits_vet_data[check.type_code].claiming === true && limits.inner_limits_vet_data[check.type_code].total_visits">
                              <b-row class="pb-2 justify-content-left">
                                <b-col cols="2" class="font-weight-bold">Number of appointments</b-col>
                                <b-col cols="2" class="font-weight-bold">Cost per appointment</b-col>
                                <b-col cols="2" class="font-weight-bold">Total cost</b-col>
                                <b-col cols="2" class="font-weight-bold">Limit</b-col>
                                <b-col cols="2" class="font-weight-bold">Total deduction amount</b-col>
                                <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                              </b-row>
                              <b-row class="pb-2 justify-content-left">
                                <b-col cols="2" v-html="limits.inner_limits_vet_data[check.type_code].total_visits"></b-col>
                                <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount)"></span></b-col>
                                <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits)"></span></b-col>
                                <b-col cols="2">&pound;<span v-html="formatToDecimal(check.limit)"></span></b-col>
                                <b-col cols="2">&pound;<span v-html="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit)"></span></b-col>
                                <b-col cols="2">
                                  <p v-if="check.deduction_added">
                                    <i class="fas fa-check-circle green icon-large"></i>
                                    Already added
                                  </p>
                                  <b-button 
                                    v-else-if="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit) > 0"
                                    variant="warning" 
                                    @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount * limits.inner_limits_vet_data[check.type_code].total_visits, check.limit));"
                                    >Add Deduction</b-button>
                                  <p v-else>
                                    <i class="fas fa-times-circle red icon-large"></i>
                                    Not needed
                                  </p>
                                </b-col>
                              </b-row>
                            </template>
                            <template v-else-if="limits.inner_limits_vet_data[check.type_code].claiming === true">
                              <b-row class="pb-2 justify-content-left">
                                <b-col cols="2" class="font-weight-bold">Cost entered by vet</b-col>
                                <b-col cols="2" class="font-weight-bold">Limit</b-col>
                                <b-col cols="2" class="font-weight-bold">Deduction amount</b-col>
                                <b-col cols="2" class="font-weight-bold">Add Deduction</b-col>
                              </b-row>
                              <b-row class="pb-2 justify-content-left">
                                <b-col cols="2">&pound;<span v-html="formatToDecimal(limits.inner_limits_vet_data[check.type_code].amount)"></span></b-col>
                                <b-col cols="2">&pound;<span v-html="formatToDecimal(check.limit)"></span></b-col>
                                <b-col cols="2">&pound;<span v-html="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit)"></span></b-col>
                                <b-col cols="2">
                                  <p v-if="check.deduction_added">
                                    <i class="fas fa-check-circle green icon-large"></i>
                                    Already added
                                  </p>
                                  <b-button 
                                    v-else-if="innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit) > 0"
                                    variant="warning" 
                                    @click="addDeductionWithPrefillData(check.id, 0, 'Inner Limits: '+check.type, innerLimitDeductionCalculate(limits.inner_limits_vet_data[check.type_code].amount, check.limit));"
                                    >Add Deduction</b-button>
                                  <p v-else>
                                    <i class="fas fa-times-circle red icon-large"></i>
                                    Not needed
                                  </p>
                                </b-col>
                              </b-row>
                            </template>
                          </b-col>
                        </template>
                      </b-row>
                      <b-button class="align-right mt-3" variant="success" @click="processLimitChecks(manualChecks[0].id)">Next</b-button>
                    </template>
                    <template v-if="this.manual_checks_step == 'Loss code checks'">
                        <h4>{{ this.manual_checks_step }}</h4>
                        <h5>Set Loss Code</h5>
                        <b-select class="mt-2 mb-4 col-10" v-model="selected_loss_code">
                          <b-select-option :value="null">-- Please select --</b-select-option>
                          <b-select-option
                            v-for="(code, code_key) in quote.available_loss_codes"
                            :key="code_key"
                            :value="code.id"
                            v-html="code.code + ' - ' + code.description"
                          ></b-select-option>
                        </b-select>
                        <b-button 
                          v-if="selected_loss_code !== null"
                          variant="success" 
                          class="ml-2 mt-2 mb-4" 
                          @click="setLossCode(); approveCsr([manualChecks[0].id], true);"
                        >Save</b-button>

                    </template>
                    <template v-if="this.manual_checks_step.startsWith('Check uploaded document is valid')">
                      <h4>Check uploaded document is valid</h4>
                      <p>{{ manual_checks_step.replace('Check uploaded document is valid ','')}}</p>
                      <b-button class="mr-2" variant="danger" v-b-modal="'status-modal-decline'">Decline Claim</b-button>
                      <b-button class="mr-2" variant="warning" @click="addDeductionWithPrefillData(manualChecks[0].id, 0, 'Invalid Document ' + manual_checks_step.replace('Check uploaded document is valid ',''));">Add Deduction</b-button>
                      <b-button variant="success" @click="approveCsr([manualChecks[0].id], true);">Document Is Valid</b-button>
                    </template>
                    <template v-if="this.manual_checks_step == 'Do we need to make any deductions from the claim'">
                      <h4>{{ this.manual_checks_step }}</h4>
                      <p>Are there any final deductions to make?</p>
                      <b-button class="mr-2" variant="warning" @click="addDeductionWithPrefillData(0, 0, '');">Yes (Add Deduction)</b-button>
                      <b-button variant="success" @click="approveClaim">Approve Claim</b-button>
                    </template>
                  </template>
                </b-tab>

              </b-tabs>

            </b-tab>

            <b-tab v-if="quote.payments" title="Payments" ref="tab_payments">

              <table class="table">

                  <tr>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Approved By</th>
                  </tr>

                  <tr v-for="(payment, indexH) in quote.payments" :key="'payment_' + indexH">
                    <td>{{payment.amount}}</td>
                    <td>{{payment.status_display}}</td>
                    <td>{{payment.approved_by}}</td>
                  </tr>

              </table>

            </b-tab>

            <notes-tab v-if="quote" v-bind:type="'claim'" v-bind:object="quote.id"></notes-tab>

            <correspondence-tab v-if="quote" v-bind:type="'claim'" v-bind:object="quote.id" ></correspondence-tab>

            <tasks-tab v-if="quote" v-bind:type="'claim'" v-bind:object="quote.id"></tasks-tab>

            <b-tab v-if="quote.limits.length > 0" title="Limits">

                <table class="table">

                  <tr>
                    <th>Section</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Limit</th>
                    <th>Remaining</th>
                  </tr>

                  <tr v-for="(limit, indexJ) in quote.limits" :key="'show_limits_' + indexJ">
                    <template v-if="limit.limit > 0">
                      <td>{{limit.section}}</td>
                      <td>{{limit.limit_type}}</td>
                      <td>{{limit.type}}<br/>{{limit.payout_type}}</td>
                      <td v-if="limit.amount_type === 'decimal'">&pound;{{limit.limit}}</td>
                      <td v-else-if="limit.amount_type === 'percentage'">{{limit.limit}}%</td>
                      <td v-if="limit.hasOwnProperty('remaining')">&pound;{{limit.remaining}}</td>
                      <td v-else>---</td>
                    </template>
                    <template v-else>
                      <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.section}}</td>
                      <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.limit_type}}</td>
                      <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.type}}<br/>{{limit.payout_type}}</td>
                      <td class="bg-danger text-dark" style="opacity: 0.5;">N/A</td>
                      <td class="bg-danger text-dark" style="opacity: 0.5;">N/A</td>
                    </template>
                  </tr>

                </table>

            </b-tab>

            <b-tab v-if="canAllocateInnerLimits" title="Inner Limits Allocation" ref="tab_inner_limits_allocation">

              <b-row v-for="(check) in innerLimitChecks" :key="'inner_limits_assign_' + check.id">
                <b-col class="py-2 px-2" v-if="check.type_code !== 'dp'">
                  <b-row>
                    <b-col class="font-weight-bold pb-3">{{ check.type }}</b-col>
                  </b-row>
                  <b-row v-if="inner_limits_allocation.hasOwnProperty(check.type_code)">
                    <b-col class="pb-3">
                      <span>Amount of claim to be allocated to this limit?</span>
                      <b-row>
                        <b-col cols="2">
                          <b-input
                            v-model="inner_limits_allocation[check.type_code]"
                          />
                        </b-col>
                        <b-col cols="10" v-if="inner_limits_allocation_error.hasOwnProperty(check.type_code)">
                          <span v-if="inner_limits_allocation_error[check.type_code] === true" style="color: red;">Must be a decimal</span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-button variant="success" @click="saveInnerLimitAllocationData">Save</b-button>
            </b-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <div>
            <b-alert variant="warning" :show="isSplitConfirmVisible">
              Are you sure you want to split this claim?<br/>
              <b-button @click="confirmSplitClaimHandler(true);" class="mr-2">Yes</b-button>
              <b-button @click="confirmSplitClaimHandler(false);" class="mr-2">No</b-button>
            </b-alert>
            <b-alert variant="warning" :show="isReopenConfirmVisible">
              Are you sure you want to reopen this claim?<br/>
              <b-button @click="confirmReopenClaimHandler(true);" class="mr-2">Yes</b-button>
              <b-button @click="confirmReopenClaimHandler(false);" class="mr-2">No</b-button>
            </b-alert>
            <b-alert variant="warning" :show="isClaimRouteConfirmVisible">
              Are you sure you want to push this claim down the assessment route?<br/>
              <b-button @click="confirmClaimRouteHandler(true);" class="mr-2">Yes</b-button>
              <b-button @click="confirmClaimRouteHandler(false);" class="mr-2">No</b-button>
            </b-alert>
          </div>
          <b-button @click="goBack" class="float-left">Back</b-button>

          <b-button v-b-modal="'change-claim-type'" variant="outline-dark" class="float-left ml-5">Edit Type</b-button>
          <!--<b-button v-if="quote && quote.status == 'Awaiting Review'" @click="confirmSplitClaim" variant="outline-dark" class="float-left ml-5">Split Claim</b-button>-->
          <b-button v-if="quote && quote.status == 'Declined'" @click="confirmReopenClaim" variant="outline-dark" class="float-left ml-5">Reopen Claim</b-button>
          <b-button v-if="quote && quote.status == 'Reopened'" @click="confirmClaimRouteEntry" variant="outline-dark" class="float-left ml-5">Re-enter Claim Route</b-button>

          <b-dropdown id="dropdown-dropup" dropup text="Contact Customer" variant="outline-dark" class=" ml-2 float-right">
            <b-dropdown-item href="#">Call</b-dropdown-item>
            <b-dropdown-item v-b-modal="'correspondence-modal-free-format'">Email</b-dropdown-item>
          </b-dropdown>

          <b-dropdown 
            v-if="quote && !['Declined', 'Settled/Paid'].includes(quote.status)"
            id="dropdown-dropup" dropup text="Update Status" variant="outline-dark" class=" ml-2 float-right">
            <b-dropdown-item 
              v-if="quote && ['Awaiting Review', 'Reopened'].includes(quote.status)"
              v-b-modal="'correspondence-modal-refer-to-underwriter'"
            >
              Refer to Underwriter
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="quote && quote.status === 'Further Policy Holder Information Required'"
              @click="approveFurtherInfoHandler"
            >
              Further Information Provided - Continue Assessment
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="quote && quote.status === 'Referred To Underwriter, Awaiting Response'"
              v-b-modal="'modal-underwriter-approval'"
            >
              Approve (As Underwriter)
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="quote && ['Awaiting Review', 'Reopened'].includes(quote.status)"
              v-b-modal="'correspondence-modal-further-info-required'"
            >
              Further Policy Holder Information Required
            </b-dropdown-item>
            <b-dropdown-item v-b-modal="'status-modal-decline'">Decline</b-dropdown-item>
          </b-dropdown>

          <b-dropdown id="dropdown-dropup" dropup text="Add" variant="outline-dark" class=" ml-2 float-right">
            <b-dropdown-item v-b-modal="'claim-add-deduction'">Deduction</b-dropdown-item>
            <b-dropdown-item v-b-modal="'task-add-task'">Task</b-dropdown-item>
            <b-dropdown-item v-b-modal="'claim-add-document'">Document</b-dropdown-item>
            <b-dropdown-item v-b-modal="'add-note'">Note</b-dropdown-item>
          </b-dropdown>

        </template>
      </b-card>
    </b-col>
  </b-row>

    <!-- Add -->
    <add-document ></add-document>
    <add-deduction :preset_description="deduction_description" :preset_amount="deduction_amount" :update_csr="update_csr" :update_blocker="update_blocker"></add-deduction>
    <add-task v-if="quote" v-bind:task_type="'claim'" v-bind:reference="quote.policy_number"></add-task>

    <change-claim-type v-if="loaded" v-bind:claim_type_in="quote.data.claim_type"></change-claim-type>

    <!-- Update Status -->
    <correspondence-modal v-if="loaded" v-bind:type="'claim'" v-bind:object="quote.id" v-bind:correspondence_type="'claim'" v-bind:status="'refer-to-underwriter'" v-bind:reference="quote.reference"></correspondence-modal>
    <correspondence-modal v-if="loaded" v-bind:type="'claim'" v-bind:object="quote.id" v-bind:correspondence_type="'claim'" v-bind:status="'further-info-required'" v-bind:reference="quote.reference"></correspondence-modal>
    <decline-modal v-if="loaded" v-bind:object="quote.id" v-bind:correspondence_type="'claim'" v-bind:reference="quote.reference"></decline-modal>

    <!-- Contact Customer -->
    <contact-customer-modal v-if="loaded" v-bind:object="quote.id" v-bind:correspondence_type="'claim'" v-bind:status="'free-format'" v-bind:reference="quote.reference"></contact-customer-modal>

    <!-- -->
    <decline-review-modal v-if="loaded" v-bind:object="quote.id" v-bind:correspondence_type="'claim'" v-bind:reference="quote.reference"></decline-review-modal>

    <div class="row justify-content-md-center">
      <b-modal
        ref="spark-confirm-modal"
        centered
        hide-footer
        title="Confirm Action"
      >
        <div class="d-block text-left">
          <h4 v-html="confirm.title"></h4>
          <p v-html="confirm.message"></p>
        </div>
        <b-row>
          <b-col>
            <b-button
              size="md"
              class="mt-5 rounded green-bg green-border text-light font-weight-bold"
              @click="handleConfirmCancel"
            >Cancel</b-button>
          </b-col>
          <b-col>
            <b-button
              size="md"
              class="mt-5 rounded red-bg red-border text-light font-weight-bold float-right"
              @click="handleConfirmOK"
            >OK</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import addDocument from './utils/add-document'
  import addDeduction from './utils/add-deduction'
  import addNote from './utils/add-note'
  import changeClaimType from './utils/change-claim-type'
  import addTask from '../task/add-task-modal'
  import correspondenceTab from '../utils/tabs/correspondence'
  import diaryTab from '../utils/tabs/diary'
  import notesTab from '../utils/tabs/notes'
  import tasksTab from '../utils/tabs/tasks'
  import correspondenceModal from '../utils/modal/correspondence'
  import DeclineModal from '../utils/modal/decline-claim'
  import DeclineReviewModal from '../utils/modal/decline-claim-review'
  import ContactCustomerModal from '../utils/modal/contact-customer'
  import file_modal from '../utils/modal/file'
  import policyTab from '../utils/tabs/policy'

  export default {
    name: 'Claim',
    components: {
      Id, addDocument, addDeduction, addTask,
      'correspondence-tab': correspondenceTab,
      'diary-tab': diaryTab,
      'notes-tab': notesTab,
      'tasks-tab': tasksTab,
      'policy-tab': policyTab,
      'add-note': addNote,
      'correspondence-modal': correspondenceModal,
      'decline-modal': DeclineModal,
      'contact-customer-modal': ContactCustomerModal,
      'decline-review-modal': DeclineReviewModal,
      'change-claim-type': changeClaimType,
      'filemodal': file_modal,

    },
    props: {
      caption: {
        type: String,
        default: 'Policy Reference '
      },
    },
    data: () => {
      return {
        loaded: false,
        quote: null,
        claim_sub_type: null,
        blockers: null,
        deductions: {data:[]},
        limits: {data:[]},
        pdf: null,
        pdf_link: null,
        get_document: false,
        to_email: '',
        toEmailState: null,
        email_options: [],
        sending_email: false,
        options: {
          claim_sub_types: []
          
        },
        assessment_step: null,
        manual_checks_step: null,
        selected_exclusion_checks: [],
        selected_exclusion_checks_claim: [],
        selected_fraud_checks: [],
        selected_limit_checks: [],
        deduction_description: "",
        deduction_internal_reason: "",
        deduction_amount: "0",
        update_csr: 0,
        update_blocker: 0,
        refresh_key: 'default',
        activeClaimTab: 0,
        is_split_confirm_visible: false,
        is_split_success_visible: false,
        is_reopen_confirm_visible: false,
        is_reopen_success_visible: false,
        is_claim_route_confirm_visible: false,
        is_claim_route_success_visible: false,
        confirm: {
          title: "",
          message: "",
          fn: null,
          args: null
        },
        cost_containment_medication_search: "",
        cost_containment_medication_manual: "",
        cost_containment_medication_search_results: [],
        cost_containment_medication_selected: null,
        cost_containment_medication_quantity: "",
        cost_containment_medication_gross: "",
        cost_containment_medication_vat: "",
        cost_containment_medication_net: "",
        cost_containment_medication_total: "",
        cost_containment_medication_in_claim: [],
        continuation_claim_linked: {},
        continuation_claim_selected_linked: null,
        continuation_claim_selected_all: null,
        continuation_claim_preview: null,
        selected_loss_code: null,
        inner_limits_allocation: {},
        inner_limits_allocation_error: {},
      }
    },
    methods: {
      jumpToTab(name) {
        let found = this.$refs["general-tab"].tabs.filter(tab => 
          tab.title.toLowerCase().replace(/\s/g, '_') === name
        );
        console.log(found);
        if (found.length === 1) {
          found[0].activate()
        }
      },
      openConfirmModal(title, message, fn, args=[]) {
        this.confirm = {
          title: title,
          message: message,
          fn: fn,
          args: args,
        }
        this.$refs["spark-confirm-modal"].show();
      },
      resetConfirmModal() {
        this.confirm = {
          title: "",
          message: "",
          fn: null,
          args: null
        }
      },
      handleConfirmCancel() {
        this.$refs["spark-confirm-modal"].hide();
        this.resetConfirmModal();
      },
      handleConfirmOK() {
        this.$refs["spark-confirm-modal"].hide();
        this.confirm.fn(...this.confirm.args);
        this.resetConfirmModal();
      },
      goBack() {
        this.$router.go(-1)
      },
      isEmpty(obj) {
        return Object.keys(obj).length === 0;
      },
      isObject (o) { 
        return typeof o == "object" 
      },
      loadDeductions () {

        axios.get('/claims/' + this.$route.params.id + '/deductions/').then(
          response => {
            this.deductions = response.data
          }
        )

      },
      loadLimits () {

        axios.get('/claims/' + this.$route.params.id + '/limits/').then(
          response => {
            this.limits = response.data
          }
        )

      },
      
      loadBlockers () {

        axios.get('/claims/' + this.$route.params.id + '/route-list/').then(
          response => {
            this.$store.state.loading = false;
            this.blockers = response.data
            this.options = response.data.data.options
            this.startAssessmentSetup();
            this.scrollToTop();
          }
        )

        this.getCostContainmentItemInClaim();

      },
      loadContinuationClaimData() {
        let linked = this.continuationClaimAudit.data.possible_linked_claim_ids;
        this.$store.state.loading = true;

        let url = '/claims/' + this.$route.params.id + '/continuation-linked/?linked=';

        url += linked.join(",")

        axios.get(url).then(
          response => {
            this.continuation_claim_linked = response.data
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      setContinuationLinkedClaim() {
        let data = {
          previous_claim: this.continuation_claim_preview.id
        }

        this.$store.state.loading = true

        axios.post('/claims/' + this.$route.params.id + '/continuation-linked-set/', data).then(
          response => {
            this.$store.state.loading = false
            this.blockerUpdate(this.blockersNotAssessment[0].id, "approved");
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      previewContinuationClaimData(type) {
        if(type === "linked") {
          this.continuation_claim_preview = this.continuation_claim_linked.linked.filter(item => (
            item.id === this.continuation_claim_selected_linked
          ))[0];
        } else if(type === "all") {
          this.continuation_claim_preview = this.continuation_claim_linked.all.filter(item => (
            item.id === this.continuation_claim_selected_all
          ))[0];
        }
        this.$forceUpdate();
      },
      setLossCode() {
        let data = {
          loss_code: this.selected_loss_code,
          claim_route: false,
        }

        this.$store.state.loading = true

        axios.patch('/claims/' + this.$route.params.id + '/', data).then(
          response => {
            
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      saveInnerLimitAllocationData(){
        let error = false;
        Object.keys(this.inner_limits_allocation).forEach(code => {
          if(this.isValidDecimal(this.inner_limits_allocation[code])) {
            this.inner_limits_allocation_error[code] = false;
          } else {
            this.inner_limits_allocation_error[code] = true;
            error = true;
          }
        })
        this.$forceUpdate()
        if(error === false) {
          this.$store.state.loading = true

          let data = this.inner_limits_allocation;

          axios.post('/claims/' + this.$route.params.id + '/limits-save/', data).then(
            response => {
              this.getPolicy("general")
              this.$store.state.loading = false
            }
          ).catch(error => {
              this.$store.state.loading = false
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }

              console.log(error.response)
            })
        }
      },
      approveFurtherInfo() {
        let data = {
          status: 11
        }

        this.$store.state.loading = true

        axios.patch('/claims/' + this.$route.params.id + '/', data).then(
          response => {
            if (response.data.refresh ) {
              this.getPolicy("general")
            }
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      approveFurtherInfoHandler() {
        var title = "Approve Further Information";
        var message = "Please confirm you have reviewed the extra information the customer has provided, and that you are happy to continue with the assessment with the extra information available.";
        this.openConfirmModal(title, message, this.approveFurtherInfo);
      },
      updateClaimSubType(event) {

        let data = {
          claim_sub_type: event
        }

        this.$store.state.loading = true

        axios.patch('/claims/' + this.$route.params.id + '/', data).then(
          response => {
            if (response.data.refresh ) {
              this.loadBlockers()
              this.getPolicy()
            }
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })

      },
      blockerUpdate(id, new_status) {

        let data = {
          record_id: id,
          update_type: 'blocker',
          new_status: new_status,
        }

        this.$store.state.loading = true

        axios.patch('/claims/' + this.$route.params.id + '/route-update/', data).then(
          response => {
            if (response.data.refresh ) {
              
            }
            this.getPolicy();
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        this.$store.state.loading = true;

      },
      approveCsr(data_list, next=false) {

        let error = false;
        this.$store.state.loading = true

        let data = Array();

        for ( let item in data_list) {
          data.push({
            record_id: data_list[item],
            update_type: 'csr',
            new_status: 'approved'
          });
        }

        axios.patch('/claims/' + this.$route.params.id + '/route-update-multiple/', data).then(
          response => {
            if (response.data.refresh ) {
              next = true;
            }
          }
        ).catch(error => {
            error = true
            //this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })

        this.$store.state.loading = false;
        if(next){
          this.$store.state.loading = true;
          setTimeout(() => {
            this.getPolicy();
          },1000);
        }
      },
      csrUpdate(event, type, id) {

        if (event === 'declined') {

          this.$bvModal.show('status-modal-decline')

        } else {

        let data = {
          record_id: id,
          update_type: 'csr',
          new_status: event
        }

        this.$store.state.loading = true

        axios.patch('/claims/' + this.$route.params.id + '/route-update/', data).then(
          response => {
            if (response.data.refresh ) {
              this.getPolicy()
            }

            this.loadBlockers()
            this.$store.state.loading = false
          }
        ).catch(error => {

            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })

        }

      },
      searchCostContainment(name) {
        let data = {
          name: name
        }

        this.$store.state.loading = true

        axios.post('/claims/medication/search/', data).then(
          response => {
            this.cost_containment_medication_search_results = Array()
            this.cost_containment_medication_search_results.push({
              id: "empty",
              name: "-- Please select --"
            })
            response.data.forEach(item => {
              this.cost_containment_medication_search_results.push(item);
            })
            this.cost_containment_medication_search_results.push({
              id: "not_listed",
              name: "Not Listed"
            })
            this.$store.state.loading = false
          }
        ).catch(error => {

            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      autocalculateCostContainmentAmounts() {
        if (this.costContainmentQuantityIsValid && this.costContainmentGrossIsValid) {
          let quantity = parseInt(this.cost_containment_medication_quantity);
          let gross = parseFloat(this.cost_containment_medication_gross);
          let total = (gross * quantity).toFixed(2);
          let vat = (total / 5).toFixed(2);
          let net = (total - vat).toFixed(2);
          this.cost_containment_medication_vat = vat;
          this.cost_containment_medication_net = net;
          this.cost_containment_medication_total = total;
        } else {
          this.cost_containment_medication_vat = "";
          this.cost_containment_medication_net = "";
          this.cost_containment_medication_total = "";
        }
      },
      addCostContainmentItemToClaim() {
        let data = {
          record_id: this.cost_containment_medication_selected,
          name: this.cost_containment_medication_manual,
          quantity: this.cost_containment_medication_quantity,
          total: this.cost_containment_medication_total,
          net: this.cost_containment_medication_net,
          vat: this.cost_containment_medication_vat,
          gross: this.cost_containment_medication_gross,
        }

        this.$store.state.loading = true

        axios.post('/claims/' + this.$route.params.id + '/add-cost-containment/', data).then(
          response => {
            this.getCostContainmentItemInClaim();
            this.$store.state.loading = false
            if (response.data.auto_decline_claim === true) {
              alert("claim declined, deduction caused claim amount to be zero")
              this.getPolicy("general");
            }
          }
        ).catch(error => {
            console.error(error)
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      getCostContainmentItemInClaim() {
        this.$store.state.loading = true

        axios.get('/claims/' + this.$route.params.id + '/get-cost-containment/').then(
          response => {
            this.cost_containment_medication_in_claim = response.data;
            this.$store.state.loading = false
          }
        ).catch(error => {

            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
      },
      getPolicy(tab=null) {

        let url = null;
        if (localStorage.getItem('selected_product')) {
          url = '/claims/' + this.$route.params.id + '/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/claims/' + this.$route.params.id + '/';
        }

        this.loadBlockers()
        this.loadDeductions()
        this.loadLimits()

        axios.get(url).then(
          response => {
            this.quote = response.data
            //this.email_options = response.data.email_documentation_emails
            this.options = response.data.options
            this.loaded = true
            this.claim_sub_type = response.data.claim_sub_type
            if (tab) {
              this.jumpToTab(tab);
            }
          }
        ).catch(error => {

            // Unauthorised
            console.error(error)
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
        return this.quote
      },
      b64toBlob(b64Data, contentType, name) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType, filename: name});
        return blob;
      },
      async getDocumentation() {

        this.get_document = true;

        try {
          const pdf_response = await axios.get('/policies/documentation/' + this.$route.params.id + '/').catch(
            error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('document error');
              console.log(error.response)
              this.get_document = false;
          })

          //let blob = new Blob([base64url.decode(pdf_response.data.data)], { type: "application/pdf", filename: this.quote.policy_number + ".pdf" });

          let blob = this.b64toBlob(pdf_response.data.data, "application/pdf", this.quote.policy_number + ".pdf");

          let blobUrl = URL.createObjectURL(blob);

          window.open(blobUrl, '_blank');

          //window.open(blobUrl);

          this.pdf_link = blobUrl; //"data:application/pdf;base64," + pdf_response.data.data

          this.$bvToast.show('document-toast')

        } catch(err){
          this.get_document = false;
          console.log(err)
        }

      },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.toEmailState = valid
        return valid
      },
      resetModal() {
        this.to_email = ''
        this.toEmailState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        this.sending_email = true
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.sending_email = false
          return
        }
        // Send to server

        let data = {};
        data.to_email = this.to_email

        axios.post('/policies/email-documentation/' + this.$route.params.id + '/', data).then(response => {

              // Unauthorised
              if (response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('email document error');
              console.log(response.data)

              this.$bvToast.show('email-document-toast')

              this.sending_email = false;
              this.$refs['email-modal'].hide()

        }).catch(
            error => {
              this.sending_email = false

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('email document error');
              console.log(error.response)

          })

        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      confirmSplitClaim(){
        this.is_split_confirm_visible = true;
      },
      confirmReopenClaim(){
        this.is_reopen_confirm_visible = true;
      },
      confirmClaimRouteEntry(){
        this.is_claim_route_confirm_visible = true;
      },
      confirmSplitClaimHandler(action){
        this.is_split_confirm_visible = false;
        if(action){
          this.splitClaim();
        }
      },
      confirmReopenClaimHandler(action){
        this.is_reopen_confirm_visible = false;
        if(action){
          this.reopenClaim();
        }
      },
      confirmClaimRouteHandler(action){
        this.is_claim_route_confirm_visible = false;
        if(action){
          this.claimRouteEntry();
        }
      },
      splitClaim(){

        this.$store.state.loading = true

        axios.post('/claims/' + this.$route.params.id + '/split-claim/', {}).then(
          response => {
            if (response.data.data ) {
              this.showSplitSuccess();
              this.$router.push({'name': 'Claim', 'params': {'id': response.data.data}});
            }
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        this.$store.state.loading = true;
      },
      reopenClaim(){

        this.$store.state.loading = true

        axios.post('/claims/' + this.$route.params.id + '/reopen-claim/', {}).then(
          response => {
            if (response.data.data ) {
              this.showReopenSuccess();
              this.$router.push({'name': 'Claim', 'params': {'id': response.data.data}});
            }
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        this.$store.state.loading = true;
      },
      claimRouteEntry(){

        this.$store.state.loading = true

        axios.post('/claims/' + this.$route.params.id + '/claim-route/', {}).then(
          response => {
            if (response.data.data ) {
              this.showClaimRouteSuccess();
              this.getPolicy()
            }
            this.$store.state.loading = false
          }
        ).catch(error => {
            this.$store.state.loading = false
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        this.$store.state.loading = true;
      },
      showSplitSuccess(){
        //this.is_split_success_visible = true;
        alert('Claim Split Success');
      },
      showReopenSuccess(){
        //this.is_reopen_success_visible = true;
        alert('Claim Reopen Success');
      },
      showClaimRouteSuccess(){
        this.is_claim_route_success_visible = true;
      },
      hideSplitSuccess(){
        this.is_split_success_visible = false;
      },
      hideReopenSuccess(){
        this.is_reopen_success_visible = false;
      },
      hideClaimRouteSuccess(){
        this.is_claim_route_success_visible = false;
      },
      startAssessmentSetup(){
        if(!this.canShowAssessmentHelper) return false;
        if(this.blockersNotAssessment.length > 0){
          this.assessment_step = "extra_blockers";
        } else if(this.fraudChecks.length > 0){
          this.assessment_step = "fraud_checks";
        } else if(this.exclusionChecksAll.length > 0){
          this.assessment_step = "exclusion_checks_all";
        } else if(this.exclusionChecksClaim.length > 0){
          this.assessment_step = "exclusion_checks_claim";
        } else if(this.manualChecks.length > 0){
          this.assessment_step = "manual_checks";
        }
      },
      processExclusionChecksAll(){
        console.log(this.selected_exclusion_checks);
        if(this.selected_exclusion_checks.length > 0){
          // some are selected
          var decline_master = false;
          for(var i = 0; i < this.selected_exclusion_checks.length; i++){
            var check = this.selected_exclusion_checks[i];
            var check_item = this.exclusionChecksAll.filter(c => (c.id == check))[0];

            if(check_item.exclusion.decline){
              decline_master = true;
            }
          }
          if(decline_master){
            this.$bvModal.show('status-modal-decline');
          }
        } else {
          // none are selected, pass all
          let list = [];
          for(var i = 0; i < this.exclusionChecksAll.length; i++){
            list.push(this.exclusionChecksAll[i].id)
          }
          this.approveCsr(list, true);
        }
      },
      processExclusionChecksClaim(){
        console.log(this.selected_exclusion_checks_claim);
        if(this.selected_exclusion_checks_claim.length > 0){
          // some are selected
          var decline_master = false;
          for(var i = 0; i < this.selected_exclusion_checks_claim.length; i++){
            var check = this.selected_exclusion_checks_claim[i];
            var check_item = this.exclusionChecksClaim.filter(c => (c.id == check))[0];

            if(check_item.exclusion.decline){
              decline_master = true;
            }
          }
          if(decline_master){
            this.$bvModal.show('status-modal-decline');
          }
        } else {
          // none are selected, pass all
          let list = [];
          for(var i = 0; i < this.exclusionChecksClaim.length; i++){
            list.push(this.exclusionChecksClaim[i].id)
          }
          this.approveCsr(list, true);
        }
      },
      processFraudChecks(){
        console.log(this.selected_fraud_checks);
        if(this.selected_fraud_checks.length > 0){
          // some are selected
          this.$bvModal.show('status-modal-decline');
        } else {
          // none are selected, pass all
          let list = [];
          for(var i = 0; i < this.fraudChecks.length; i++){
            list.push(this.fraudChecks[i].id)
          }
          this.approveCsr(list, true);
        }
      },
      innerLimitDeductionCalculate(vet_amount, limit_amount) {
        if (vet_amount <= limit_amount) return this.formatToDecimal(0.00);
        return this.formatToDecimal(vet_amount - limit_amount);
      },
      processLimitChecks(csr_id){
        console.log(this.selected_limit_checks);
        if(this.selected_limit_checks.length > 0){
          // some are selected
          //this.$bvModal.show('status-modal-decline');
        } else {
          // none are selected
          this.approveCsr([csr_id], true);
        }
      },
      addDeduction(){
        this.deduction_description = '';
        this.update_csr = 0;
        this.update_blocker = 0;
        this.deduction_amount = 0;
        this.$bvModal.show('claim-add-deduction');
      },
      addDeductionWithPrefillData(csr_id, blocker_id, value,  amount=null){
        this.deduction_description = value;
        this.update_csr = csr_id;
        this.update_blocker = blocker_id;
        if (amount) {
          this.deduction_amount = amount;
        }
        this.$bvModal.show('claim-add-deduction');
      },
      calculatePercentageDeduction(percentage, price) {
        return this.formatToDecimal((price / 100) * percentage)
      },
      approveClaim(){
        this.approveCsr([this.manualChecks[0].id], true);
        this.blockerUpdate(this.blockerAssessment[0].id, "approved");
        this.getPolicy("inner_limits_allocation");
        //this.$router.push("/customer-service/claims/"+this.quote.id);
        //this.refresh_key = "new"+this.manualChecks[0].id;
        this.scrollToTop();
      },
      formatToDecimal(value) {
        if (!value) return value;
        return parseFloat(value).toFixed(2);
      },
      openInNewTab(url){
        window.open(url, "_blank");
      },
      scrollToTop(){
        window.scrollTo(0,0);
      },
      isValidDecimal(number){
        var str = number.toString();
        if(str.indexOf(".") == -1) return false;
        if(str.split('.')[1].length === 2) return true;
        return false;
      }
    },
    computed: {
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      canShowAssessmentHelper(){
        if(this.blockers === null) return false;
        return (this.blockers.data.blockers.length > 0 && this.quote && this.quote.status == "Awaiting Review")
      },
      blockerAssessment(){
        if(this.blockers === null) return [];
        return this.blockers.data.blockers.filter(exc => (exc.reason === "Assessment Investigation"))
      },
      blockersNotAssessment(){
        if(this.blockers === null) return [];
        return this.blockers.data.blockers.filter(block => (
          (block.reason !== "Assessment Investigation" && block.reason !== "Claim handler Checks") && block.status !== "approved")
        )
      },
      canAllocateInnerLimits(){
        if(this.quote.payments.length === 0) return false;
        let awaiting_limits = this.quote.payments.filter(payment => (
          (payment.status === 5)
        ));
        return awaiting_limits.length > 0
      },
      continuationClaimAudit(){
        if(this.quote.audit.length === 0) return [];
        return this.quote.audit.filter(audit => (
          (audit.name == "Has the claim been flagged as a possible continuation claim?")
        ))[0];
      },
      exclusionChecksAll(){
        if(this.blockers === null) return [];
        return this.blockers.data.exclusions.filter(exc => (exc.exclusion.type == "All" && exc.status === "review"))
      },
      exclusionChecksClaim(){
        if(this.blockers === null) return [];
        return this.blockers.data.exclusions.filter(exc => (exc.exclusion.type !== "All" && exc.status === "review"))
      },
      fraudChecks(){
        if(this.blockers === null) return [];
        return this.blockers.data.fraud_checks.filter(exc => (exc.status === "review"))
      },
      manualChecks(){
        if(this.blockers === null) return [];
        let checks = this.blockers.data.csr_checks.filter(exc => (exc.status === "review"))
        if(checks.length > 0){
          this.manual_checks_step = checks[0].description;
        }
        return checks
      },
      canAddCostContainmentItem() {
        if (this.cost_containment_medication_selected === null) return false;
        if (this.cost_containment_medication_selected === "empty") return false;
        if (this.cost_containment_medication_selected === "not_listed") {
          if (this.cost_containment_medication_manual.length < 1) return false;
        }
        return true;
      },
      costContainmentQuantityIsValid() {
        if (this.cost_containment_medication_quantity.length > 0) {
          if (!/^[0-9]+$/.test(this.cost_containment_medication_quantity)) return false;
          let quantity = parseInt(this.cost_containment_medication_quantity);
          if (quantity < 1) return false;
          return true;
        }
        return false;
      },
      costContainmentGrossIsValid() {
        if (this.cost_containment_medication_gross.length > 0) {
          if (!/^[0-9]+\.[0-9]{2}$/.test(this.cost_containment_medication_gross)) return false;
          let gross = parseFloat(this.cost_containment_medication_quantity);
          if (gross < 0) return false;
          return true;
        }
        return false;
      },
      innerLimitChecks(){
        if(this.quote === null) return [];
        let limits = this.quote.limits.filter(item => (item.limit_type === "Inner"));
        for(var i=0; i < limits.length; i++) {
          if(!this.inner_limits_allocation.hasOwnProperty(limits[i].type_code)) {
            this.inner_limits_allocation[limits[i].type_code] = "0.00";
          }
          if(!this.inner_limits_allocation_error.hasOwnProperty(limits[i].type_code)) {
            this.inner_limits_allocation_error[limits[i].type_code] = false;
          }
        }
        return limits;
      },
      innerLimitAppointmentChecks(){
        if(this.quote === null) return [];
        return this.innerLimitChecks.filter(item => (item.payout_type === "Limit Per Appointment"))
      },
      innerLimitConditionChecks(){
        if(this.quote === null) return [];
        return this.innerLimitChecks.filter(item => (item.payout_type === "Limit Per Condition"))
      },
      innerLimitPolicyChecks(){
        if(this.quote === null) return [];
        return this.innerLimitChecks.filter(item => (item.payout_type === "Limit Per Policy Period"))
      },
      isSplitConfirmVisible(){
        return this.is_split_confirm_visible;
      },
      isSplitSuccessVisible(){
        return this.is_split_success_visible;
      },
      isReopenConfirmVisible(){
        return this.is_reopen_confirm_visible;
      },
      isReopenSuccessVisible(){
        return this.is_reopen_success_visible;
      },
      isClaimRouteConfirmVisible(){
        return this.is_claim_route_confirm_visible;
      },
      isClaimRouteSuccessVisible(){
        return this.is_claim_route_success_visible;
      },
      claimDataOrdered() {
        if (!this.quote.claim_data) return null;
        return Object.values(this.quote.claim_data).sort((a, b) => a.order - b.order);
      },
      vetClaimDataOrdered() {
        if (!this.quote.vet_claim_data) return null;
        return Object.values(this.quote.vet_claim_data).sort((a, b) => a.order - b.order);
      }
    },
    mounted() {
      this.getPolicy()
      //alert(this.quote.claim_type)
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getPolicy()
        );

      },
      cost_containment_medication_search: function() {
        if (this.cost_containment_medication_search.length > 0) {
          this.searchCostContainment(this.cost_containment_medication_search);
        }
      },
      cost_containment_medication_quantity: function() {
        this.autocalculateCostContainmentAmounts();
      },
      cost_containment_medication_gross: function() {
        this.autocalculateCostContainmentAmounts();
      }
    }
  }
</script>

<style>
  /*
  #data .card-header {
    padding-top: 1.5rem;
  }

  #data .card-child .card-header {
    padding-top: 0 !important;
  }*/

  .icon-large {
  font-size: 1.4em;
  margin-right: 0.5em;
  margin-left: 0.2em;
}
.red {
  color: #ff0000 !important;
}
.green {
  color: #00b050 !important;
}
.red-bg {
  color: #ff0000 !important;
}
.green-bg {
  color: #00b050 !important;
}
.red-border {
  color: #ff0000 !important;
}
.green-border {
  color: #00b050 !important;
}

.fa-paw {
  color: #3e8ede;
  font-size: 1.4em;
  -webkit-transform: rotate(330deg);
  -moz-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  -o-transform: rotate(330deg);
  transform: rotate(330deg);
  margin-right: 1rem;
}
</style>
